/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).ready(function(){

        	console.log("Setting date fields.");
        	$(".date-field input").prop("type", "date");


        	$("a[href='#']").click(function(e){
        		e.preventDefault();
        	});


        	if(window.innerWidth > 767)
        	{
        		$("li.dropdown").unbind();

	        	var menuHovered = false;
	        	var timeoutID = 0;

	        	$("li.dropdown").hover(function(){
	        		var currentDropdown = $(this).find(".dropdown-menu");
	        		$(".dropdown-menu").not(currentDropdown).stop().slideUp(250);
	      			currentDropdown.stop().slideDown(250);
	      			window.clearTimeout(timeoutID);
	      			menuHovered = false;
	      		}, function(){
	      			var parent = $(this);
	      			timeoutID = window.setTimeout(function(){
	      				//console.log("The timeout is executing - checking if menu has been hovered >> " + menuHovered);
	      				if(!menuHovered)
	      				{
	      					parent.find(".dropdown-menu").eq(0).stop().slideUp(250);
	      				}
	      			}, 1000);

	      			//console.log("Left parent, created timeout = " + timeoutID);

	      			$(this).find(".dropdown-menu").mouseenter(function(){
	      				//console.log("Since the dropdown was hovered, cleared the timeout = " + timeoutID);
	      				window.clearTimeout(timeoutID);
	      				$(this).unbind();
	      			});
	      		});
				
				$(".dropdown-menu").mouseenter(function(){
	      			//console.log("The dropdown has been hovered, so clearing the timeout = " + timeoutID);
	      			window.clearTimeout(timeoutID);
	      			menuHovered = true;
	      		});

	      		$(".dropdown-menu").mouseleave(function(){
	      			$(this).stop().slideUp(250);
	      			window.clearTimeout(timeoutID);
	      			menuHovered = false;
	      		});


				/*
				$("li.dropdown").mouseenter(function(){
					var currentDropdown = $(this).find(".dropdown-menu");
					$(".dropdown-menu").not(currentDropdown).stop().slideUp(250);
	      			currentDropdown.stop().slideDown(250);
				});

				$(".dropdown-menu").mouseleave(function(){
					$(this).slideUp(250);
				});
				*/

	      		$(".dropdown-toggle").click(function(e){
	      			e.preventDefault();
	      			if($(this)[0].hash === 'store')
	      			{
	      				return false;
	      			}
	      			else
	      			{
	      				window.location = $(this).attr("href");
	      			}
	      		});
        	}
        	else
        	{
        		
        	}


        	if(window.location.hash === '#get-into-anchor')
        	{
        		$("#get-into .class-item-dropdown-content").slideDown(250);
        	}

        	$(".pagination-left a, .pagination-right a").each(function(index, el){
        		var hrefValue = $(this).prop("href") + "#recent-posts";
        		$(this).prop("href", hrefValue);
        	});

        	$(".ginput_container_radio, .ginput_container_select").siblings("label").show();

      		$(window).load(function(){
      			var mainNavbar = $("nav.main-navigation");
	      		var originalOffset = mainNavbar.offset().top;

	      		var currentURL = window.location.href;
	      		var host       = window.location.host;
	      		currentURL = currentURL.replace(host, "").replace("https://", "").replace("http://", "");

	      		$(window).scroll(function(){
	      			if($(document).scrollTop() >= originalOffset)
	      			{
	      				mainNavbar.css({"position" : "fixed"});
	      				$(".main").css({"margin-top" : "64.5px"});
	      			}
	      			else
	      			{
	      				mainNavbar.css({"position" : "relative"});
	      				$(".main").css({"margin-top" : "0"});
	      			}
	      		});
	      		
      		});

      		$(".show-faculty-content").click(function(e){
      			e.preventDefault();
      			$(this).siblings(".faculty-content").css({"opacity" : 1});
      			$(this).fadeOut(175);
      		});

      		$(".hide-faculty-content").click(function(e){
      			e.preventDefault();
      			$(this).parent(".faculty-content").css({"opacity" : 0});
      			//console.log($(this).parent().siblings('.show-faculty-content'));
      			$(this).parent().siblings('.show-faculty-content').show();
      		});

      		
      			

      		if(window.innerWidth > 992)
      		{
      			$(".page-banner").height(window.innerHeight-200);
      		}
      		else
      		{
      			// do nothing, let css handle this
      		}
        	
        
        	$(".dropdown-menu").each(function(index, el){
        		var width = ((($(el).width() / 2) + 3) * -1);

        		$(el).css({
        			"margin-left" : width + "px"
        		});
        	});

        	$(".learnMore").click(function(){
        		$(this).siblings(".class-item-dropdown-content").slideDown();
        	});

        	$(".closeDropdown").click(function(){
        		$(this).parents(".class-item-dropdown-content").slideUp();
        	});

        	$(".more-notes-content").click(function(){
        		$(this).hide();
        		$(".less-notes-content").show();
        		$(".note-post-content").slideToggle();
        	});

        	$(".less-notes-content").click(function(){
        		$(this).hide();
        		$(".more-notes-content").show();
        		$(".note-post-content").slideToggle();
        	});

        	$(".stripe-button-el span").text("Buy Now");

        	$(".selectpicker").selectpicker();

        	$(".product-category-cost").html("$" + $(".product-order-dropdown select option:selected").data("price"));

        	$("#current-product-price-change").html($(".product-order-dropdown select option:selected").data("price"));
        	$("#current-product-name-change").html($(".product-order-dropdown select option:selected").text());
        	$("#input-product-variant").val($(".product-order-dropdown select option:selected").val());
        	$("input[name=limited_spots]").val($(".product-order-dropdown select option:selected").data("limited"));
        	$("input[name=current_number_spots]").val($(".product-order-dropdown select option:selected").data("inv"));

        	$(".product-order-dropdown select").change(function(){
        		var price = $(this).find("option:selected").data("price");
        		$(".product-category-cost").html("$" + price);
        		$("#current-product-price-change").html(price);
        		$("#current-product-name-change").html($(this).find("option:selected").text());
        		$("#input-product-variant").val($(this).find("option:selected").val());
        		$("input[name=limited_spots]").val($(this).find("option:selected").data("limited"));
        		$("input[name=current_number_spots]").val($(this).find("option:selected").data("inv"));

        	});

        	$("#buy-now").click(function(e){
        		$(".product-form").fadeIn();
        	});

        	$("#product-form-close").click(function(e){
        		$(".product-form").fadeOut();
        	});






















        	/*------------------------------------------------------------
			|  Forms
			--------------------------------------------------------------
			|  All scripts associated with forms, validation, and submission
			|  is below in this block of scripts.
			|
			*/

        	$(".subscribe-form-wrapper form .ginput_container").append($(".subscribe-form-wrapper form .gform_footer").html());

        	var forms = document.querySelectorAll("form");

        	for(var i = 0; i < forms.length; i++)
        	{
        		forms[i].onsubmit = function(){ return false; };
        	}

        	var footerFormID;

        	window.setTimeout(function(){
        		$(".subscribe-form-wrapper form").unbind();
        		var footerFormID = $(".subscribe-form-wrapper form").prop("id");
        		//console.log(document.getElementById(footerFormID));
        		document.getElementById(footerFormID).onsubmit = function(){return false;};
        	}, 250);

        	
        	$(".subscribe-form-wrapper form input[type=submit]").click(function(){
        		var email = $(".subscribe-form-wrapper form input[type=email]").val();
        		var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        		var passed = re.test(email);

        		if(!passed)
        		{	
        			$("#subscribeError").html("Please enter a valid email address.").addClass("animateError");
        			
        			window.setTimeout(function(){
        				$("#subscribeError").removeClass("animateError");
        			}, 1000);

        			return;
        		}
        		
        		document.querySelector(".subscribe-form-wrapper form").submit();
        		$("#subscribeLoader").show();

        		$(document).bind('gform_confirmation_loaded', function(event, formId){
    				
    					$("#subscribeError").html("Thank you! We'll be in touch.");
    					$("#subscribeLoader").hide();
    				
        		});
        	});

        	$("select").selectpicker();

        	$(".modal .gform_button").click(function(){
        		// need to find out the currrent form
        		var parentForm = $(this).closest("form");
        		var parentModal = parentForm.closest(".modal");

        		$(".form-error-reminder").hide();
        		var required = parentForm.find(".gfield_contains_required input").not(".gfield_contains_required.lesly-email input");
        		required.removeClass("form-error");

        		var passedRequired = true;

        		required.each(function(index, el){
        			if($(el).val().trim().length === 0)
        			{
        				// highlight this input and add class to scroll to
        				$(el).val("Please enter required field.").addClass("form-error");
        				passedRequired = false;
        			}
        		});

        		if(!passedRequired)
        		{
        			parentForm.find(".form-error-reminder").show();
        			parentForm.closest(".modal").animate({ scrollTop: 0 }, 500);
        			return;
        		}

        		if(parentForm.find("input[name=input_13]:checked").val() === "Yes")
        		{
        			var email    = parentForm.find(".lesly-email input").val();
	        		var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
	        		var passedEmail = re.test(email);

	        		if(!passedEmail)
	        		{
	        			// highlight the email field with a special message and add class to scroll to it
	        			parentForm.find(".lesly-email input").val("Please enter a valid email address.").addClass("form-error");
	        			parentForm.find(".form-error-reminder").show();
	        			parentForm.closest(".modal").animate({ scrollTop: 0 }, 500);
	        			return;
	        		}
        		}

        		
        		
        		// if we got this far, then show the loader, and submit the form
        		parentForm.closest(".modal-content-overlay").fadeIn();
        		parentForm[0].submit();

        		$(document).bind('gform_confirmation_loaded', function(event, formId){
    				if(formId === 2 || formId === 3 || formId === 5  || formId === 6)
    				{
    					parentModal.find(".modal-form-content").fadeOut(125);
    					parentModal.find(".modal-thanks-content").delay(125).fadeIn(125);
    					parentModal.find(".modal-content-overlay").fadeOut();
    				}
        		});
        	});
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
